import './offline-modal.css';
import h from 'hyperscript';
import { Modal } from 'ui/dialog/modal/modal';

import i18n from './offline-modal.properties';

export const NAME = 'offline-modal';

export class OfflineModal extends Modal {

  constructor () {
    super(null, i18n('heading'));
    this.header.prepend(h('d-progress-spinner.d-progress-spinner-s'));
    this.dialog.classList.add(NAME);

    this.addEventListener('cancel', event => event.preventDefault());

    this.body.append(h('p', i18n('msg')));

    this.footer.append(h('button.button.button-primary', { type: 'button' }, i18n('primaryBtn')));
  }

}

customElements.define(NAME, OfflineModal);
